.jaimataran{
    height: 500px;
}

.carousel-item{
    height: 100%;
    width: 100%;
}
.carousel-item img{
    height: 100%;
    width: 100%;
}


@media screen and (max-width:767px){

    .jaimataran{
        height: 450px;
    }
}


@media screen and (max-width:600px){

    .jaimataran{
        height: 400px;
    }
}

@media screen and (max-width:500px){

    .jaimataran{
        height: 350px;
    }
}

@media screen and (max-width:400px){

    .jaimataran{
        height: 300px;
    }
}