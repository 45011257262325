*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body{
  min-height: 100vh;
  width: 100%;
  background:linear-gradient(rgb(227, 220, 255), rgb(216, 209, 255));
  text-align: justify;
  overflow-x: hidden;
}

a{
  text-decoration: none;
}
/* -----------------Facilities-------------- */


/* ------------------Contact-Us---------------------- */

/* 
 */


/* ----------------------------------Acadmic---------------------- */

/* 

    
} */


/* ---------------------------Our School-------------------- */
.form{
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}
.form>img{
  width: 270px;
  margin-bottom: 1rem;
}

@media screen and (max-width:600px){
  .form>img{
    /* display: flex;
    align-items: center;
    justify-content: center;
    text-align: center; */
    width: 270px;
    /* margin: 20px; */
  }
}
@media screen and (max-width:350px)
{
  .form>img{
    width: 250px;
  }
}
/* ---------------------Director and principle say---------------- */


.principal{
  /* border: 2px solid red; */
  width: 80vw;
  margin: auto;
  padding: 50px 0;
}

.principal-row{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.principal-col img{
  margin-top: 3rem;
  width: 250px;
  height: 220px;
}
.principal-col h5{
  text-align: center;
  background-color: #0c2147;
  padding: 10px 0;
  color: #ffffff;
}
.principal-col h2, h6{
  margin-left: 50px;
}

.principal-col h2{
  font-weight: 600;
}
.principal-col h6{
  font-weight: 400;
  line-height: 25px;
}

@media (max-width:1000px){
  .principal-row{
    flex-direction: column;
  }
  .principal-col{
    margin-bottom: 50px;
  }
  .principal-col img{
    width: 500px;
    height: auto;
  }
  .principal-col h2, h6{
    margin-left: 0;
  }
}
@media (max-width: 600px){
  .principal-col img{
    margin-top: 0;
    width: 400px;
  }
  .principal-col h6{
    font-size: 14px;
    line-height: 23px;
  }
}
@media (max-width: 500px){
  .principal-col img{
    width: 300px;
  }
  
 
}
@media (max-width: 350px){
  .principal-col img{
    width: 200px;
  }
} 