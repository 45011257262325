.main-footer{
    /* height: 70vh; */
    /* border: 2px solid red; */
    background-color:#0C2147 ;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    /* margin-top: 50px; */
    /* margin-top: -50px; */
}

.socialall a{
    text-decoration: none;
    color: white;
}

.footer-top{
    /* height: 85%; */
    background-color: #0C2147;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 20px;
}

.footerlogo-img{
    height: 60%;
    /* background-color: rgb(23, 85, 199); */

}

.footerlogo-img img{
    height: 150px;
}
.footer-content{
    height: 55%;
    /* background-color: red; */
}

.socialmedia-link{
    height: 20%;
    /* background-color: rgb(67, 168, 20); */
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;

}
.socialall{
    width: 20%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: space-around;

}
.alllink-soc{
    height: 25px;
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgb(143, 141, 141);

}
.alllink-soc i{
font-size: 12px;
}

.footer-bottom{
    height: 15%;
    width: 100%;
    background-color: #081A39;

   
}
.footer-bottom p{
  /* background-color/: red; */
  text-align: center;
  margin-top: 20px;

}

.footer-bottom .footer-made {
    margin-top: -18px ;
}
.footer-bottom .footer-made a{
    text-decoration: none;
    color: rgb(227, 220, 255);
    font-weight: bold;
}

.footer-bottom .footer-made  i{
    position: relative;
  color: #a83f39 ;
}



@media screen and (max-width:767px){
    .main-footer{
        /* height: 80vh; */
        background-color:#0C2147 ;
        color: white;

    }
    .footerlogo-img{
        height: 100px;
        width: 100px;
    
    }


    .footerlogo-img img{
        height:100%;
        width: 100%;
    }
    .footer-content{
        height: 40%;
        font-size: 15px;
    }
    .alllink-soc{
        height: 25px;
        width: 25px;
    }
    .alllink-soc i{
        font-size: 12px;
        }
        .socialall{
            width: 40%;
           
        
        }
        .footer-bottom p{
            font-size: 15px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
           }
}




@media screen and (max-width:620px){
    .main-footer{
        height: 70vh;
        background-color:#0C2147 ;
        color: white;
    }
    .footerlogo-img{
        height:100px;
        width: 100px;
    }

  .footerlogo-img img{
        height:100%;
        width: 100%;
    
    }
    .footer-content{
        padding: 0px 40px;
        height: 50%;
        font-size: 14px;
    }
    .alllink-soc{
        height: 25px;
        width: 25px;
    }
    .alllink-soc i{
        font-size: 12px;
        }
        .socialall{
            width: 40%;
        }
        .footer-bottom p{
            font-size: 13px;
            text-align: center;
           }
}

@media screen and (max-width:420px){
    .main-footer{
        height: 60vh;
        background-color:#0C2147 ;
        color: white;
        /* margin-top: 70; */
    }
    .footerlogo-img{
        height:100px;
        width: 100px;
    }
    .footerlogo-img img{
        height:100%;
        width: 100%;
    }
    .footer-content{
        width: 100vw;
        padding: 0px 40px;
        height: 40%;
        font-size: 14px;
    }
    .alllink-soc{
        height: 25px;
        width: 25px;
        margin-top: 80px;
    }
    .alllink-soc i{
        font-size: 12px;
      
        }
        .socialall{
            width: 70%;
            margin-top: 10px;
        }
        .footer-bottom p{
            font-size: 12px;
            text-align: center;
            padding: 0 20px;
           }
}
