.first{
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
  
  }

  /* .container-fluid{
    background:linear-gradient(rgb(234, 231, 245), rgb(219, 215, 240));
  } */
  
  .one{
    width: 25%;
    height: 250px;
    margin: 25px;
  
  }
  .one img{
  height: 100%;
  width: 100%;
  }
  
  
  @media screen and (max-width:768px){
  
  .first{
    flex-direction: column;
  
  }
  .one{
    width: 100%;
    height: 400px;
    margin: 10px 20px;
  
  }
  }